import React from 'react';
import { makeStyles } from '@mui/styles';
import { useMediaQuery, Button, IconButton } from '@mui/material';
import { withRouter } from 'react-router';
import { Login } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2)
    }
}));

//Login Button
//Unterscheidung zwischen Mobile und Desktop Ansicht
function LoginButton(props) {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');

    let loginButton = null
    isMobile ?
        loginButton = <IconButton aria-label="add" onClick={() => props.history.push("/login")}><Login /></IconButton> :
        loginButton = <Button variant="contained" className={classes.button} onClick={() => props.history.push("/login")} >Anmelden</Button>

    return (
        loginButton
    )
}

export default withRouter(LoginButton)