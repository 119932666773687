import { useState, useEffect } from 'react';

function getSavedValue(key, initialValue) {
  const savedValue = JSON.parse(localStorage.getItem(key))
  if (savedValue) return savedValue

  if (initialValue instanceof Function) return initialValue()

  return initialValue
}

export default function useDivision(initialValue) {
  const [division, setDivision] = useState(() => {
    return getSavedValue("division", initialValue)
  });

  useEffect(() => {
    localStorage.setItem("division", JSON.stringify(division))
    window.dispatchEvent(new Event("divisionUpdate"));
    setDivision(division)
  }, [division]);

  return [division, setDivision];
}