import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useMediaQuery, Link, IconButton } from "@mui/material";
import { withRouter } from "react-router";
import { AccountCircle } from "@mui/icons-material";

import { AuthContext } from "../Home";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
  },
}));

//Login Button
//Unterscheidung zwischen Mobile und Desktop Ansicht
function AccountButton(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  const Auth = useContext(AuthContext);

  let accountButton = null;
  isMobile
    ? (accountButton = (
        <IconButton
          aria-label="add"
          onClick={() => props.history.push("/konto")}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      ))
    : (accountButton = (
        <Link
          color="inherit"
          className={classes.button}
          onClick={() => props.history.push("/konto")}
        >{`Angemeldet als: ${Auth.user.vorname} ${Auth.user.nachname}`}</Link>
      ));

  return accountButton;
}

export default withRouter(AccountButton);
