import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { BottomNavigation, BottomNavigationAction, useMediaQuery } from '@mui/material';
import { Home, Event, School, Style } from '@mui/icons-material';
import { ReactComponent as FireDepartmentLight } from "./../images/local_fire_department_light.svg";
import { withRouter } from 'react-router-dom';

import { AuthContext } from './Home';

const useStyles = makeStyles({
    bottomNav: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
    }
});

//Untere Navigationsleiste; nicht sichtbar auf mobil
function BottomNav(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState("");

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.history.push(newValue)
    };

    const Auth = useContext(AuthContext)

    return (
        <BottomNavigation
            value={value}
            onChange={handleChange}
            className={classes.bottomNav}
        >
            <BottomNavigationAction label="Neuigkeiten" value="/" icon={<Home />} />
            <BottomNavigationAction label="Kalender" value="/kalender" icon={<Event />} />
            <BottomNavigationAction label="Fortbildungen" value="/fortbildungen" icon={<School />} />
            {Auth.isLoggedIn && Auth.user && <BottomNavigationAction label="Partnercard" value="/partnercard" icon={<Style/>} />}
            {Auth.isLoggedIn && Auth.user && <BottomNavigationAction label="Feuerwehren" value="/feuerwehren" icon={<FireDepartmentLight style={{ "fill": value === 3 ? "#B51F2A" : prefersDarkMode ? "#C7C7C7" : "#757575" }} />} />}
        </BottomNavigation >
    );
}

export default withRouter(BottomNav)