import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { withRouter } from "react-router-dom";

import { AuthContext } from "../Home";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Transition } from "./Transition";

function OnboardingDialog(props) {
  const [step, setStep] = useState(0);

  const Auth = useContext(AuthContext);

  const isMobile = useMediaQuery("(max-width:600px)");
  const directory = isMobile ? "OnboardingMobile" : "OnboardingDesktop";

  const onBoardings = {
    "/": {
      title: "Startseite",
      steps: [
        {
          title: "Menü",
          display: true,
          text: "Über den Menüknopf in der oberen linken Ecke gelangen Sie zu den verschiedenen Seiten der App.",
          image: directory + "/MenuNotLoggedIn.gif",
        },
        {
          title: "Wechseln der Bereiche",
          display: true,
          text: "Unter der Kopfzeile befindet sich die Navigationsleiste, mit der Sie zwischen den Bereichen wechseln können. Hier kann zwischen KFV und KJF gewählt werden. Die Auswahl ist nur sichtbar, wenn sie eine Auswirkung auf den Inhalt der Seite hat. Dies betrifft die Bereiche Neuigkeiten und Fortbildungen.",
          image: directory + "/DivisionChange.gif",
        },
        {
          title: "Social Media",
          display: true,
          text: "Über den Neuigkeiten befinden sich rechts oben die Social Media Links des KFV LDS.",
          image: directory + "/SocialMedia.png",
        },
        {
          title: "Benachrichtigungen",
          display: Auth.isLoggedIn && Auth.user,
          text: "Wenn Sie zugelassen haben, dass die App Ihnen Benachrichtigungen senden darf kann dieses über ihr Profil gesteuert werden. Dies befindet sich oben rechts in der Kopfzeile. Hier können Sie sowohl Ihre Allgemeinen Benachrichtigungseinstellungen verwalten als auch die Feuerwehr-Abonnements.",
          image: directory + "/Notifications.gif",
        },
      ],
    },
    "/kalender": {
      title: "Startseite",
      steps: [
        {
          title: "Kalender",
          display: true,
          text: "Hier finden Sie den Veranstaltungs-Kalender des KFV LDS. Er bietet Ihnen einen Überblick über anstehende Events des Verbands. Unter der Ansicht gibt es die Möglichkeit den Kalender zu exportieren und anschließend in der Kalender-App Ihrer Wahl zu importieren.",
          image: directory + "/Calendar.png",
        },
      ],
    },
    "/fortbildungen": {
      title: "Fortbildungen",
      steps: [
        {
          title: "Filterfunktionen",
          display: true,
          text: "Unter den aufgelisteten Fortbildungen gibt es die Möglichkeit die angezeigten Fortbildungen zu filtern. Hier können vergangene Fortbildungen zur Anzeige hinzugefügt werden oder, sofern Sie über ein Konto verfügen und angemeldet sind, nur gebuchte Fortbildungen angezeigt werden.",
          image: directory + "/Fortbildung Funktionen.png",
        },
        {
          title: "Buchung",
          display: true,
          text: "Sie haben die Möglichkeit eine Fortbildung zu buchen. Drücken Sie dafür auf den Buchen Knopf. Sind Sie angemeldet werden Ihre hinterlegten Kontaktdaten genutzt. Sind Sie nicht angemeldet, können Sie sich als Gast anmelden und müssen Ihre Kontaktdaten angeben. Nach der Buchung erhalten Sie eine Bestätigung per E-Mail.",
          image:
            Auth.isLoggedIn && Auth.user
              ? directory + "/BookFortbildung.gif"
              : directory + "/BookFortbildungGuest.gif",
        },
      ],
    },
    "/feuerwehren": {
      title: "Feuerwehren",
      steps: [
        {
          title: "Abonnieren",
          display: true,
          text: "Auf der Seite der Feuerwehren können Sie sich für die Feuerwehren abonnieren, die Sie interessieren. Dazu klicken Sie auf den Abonnieren Knopf. Sie erhalten dann eine Benachrichtigung, wenn es Neuigkeiten von der Feuerwehr gibt. Eine Übersicht der abonnierten Feuerwehren finden Sie in Ihrem Profil (oben rechts in der Kopfzeile).",
          image: directory + "/SubscribeFeuerwehr.gif",
        },
        {
          title: "Kontakt",
          display: true,
          text: "Im Kontaktbereich finden Sie die wichtigsten Informationen der Feuerwehr. Hier können Sie sich direkt an die Feuerwehr wenden und finden eine Übersicht der Ansprechpartner.",
          image: directory + "/ContactFeuerwehr.png",
        },
        {
          title: "Neuigkeiten",
          display: true,
          text: "Jede Feuerwehr verfügt über eine eigene Seite, auf der Sie Neuigkeiten der Feuerwehr finden.",
          image: directory + "/NewsFeuerwehr.png",
        },
      ],
    },
  };

  useEffect(() => {
    if (
      localStorage.getItem("showOnboarding_" + props.location.pathname) ===
      "false"
    ) {
      props.setShowDialog(false);
    } else {
      props.setShowDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  const handleClose = () => {
    setStep(0);
    props.setShowDialog(false);
    localStorage.setItem("showOnboarding_" + props.location.pathname, "false");
  };

  return (
    <div>
      {props.showDialog && onBoardings[props.location.pathname] && (
        <Dialog
          TransitionComponent={Transition}
          onClose={handleClose}
          open={props.showDialog}
          fullWidth
        >
          <DialogTitle>
            {onBoardings[props.location.pathname].title + " Hilfe"}
          </DialogTitle>
          <DialogContent>
            {onBoardings[props.location.pathname].steps[step] &&
              onBoardings[props.location.pathname].steps[step].display && (
                <div>
                  <DialogContentText>
                    <Typography variant="h6">
                      {onBoardings[props.location.pathname].steps[step].title}
                    </Typography>
                    {onBoardings[props.location.pathname].steps[step].text}
                  </DialogContentText>
                  <img
                    src={onBoardings[props.location.pathname].steps[step].image}
                    alt={"stepimage_" + step}
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              )}
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Button variant="contained" onClick={handleClose} color="primary">
                Schließen
              </Button>
              {isMobile ? (
                <Grid item>
                  <IconButton
                    onClick={() => setStep(step - 1)}
                    disabled={step === 0}
                    color="primary"
                    sx={{ marginRight: "8px" }}
                  >
                    <ChevronLeft />
                  </IconButton>
                  <IconButton
                    onClick={() => setStep(step + 1)}
                    disabled={
                      step ===
                      onBoardings[props.location.pathname].steps.length - 1
                    }
                    color="primary"
                  >
                    <ChevronRight />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => setStep(step - 1)}
                    disabled={step === 0}
                    color="primary"
                    sx={{ marginRight: "8px" }}
                  >
                    Zurück
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setStep(step + 1)}
                    disabled={
                      step ===
                      onBoardings[props.location.pathname].steps.length - 1
                    }
                    color="primary"
                  >
                    Weiter
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default withRouter(OnboardingDialog);
