import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import Home from './components/Home';

ReactDOM.render(
  <React.Fragment>
    <Home />
  </React.Fragment>,
  document.getElementById('app')
);

serviceWorkerRegistration.register();
